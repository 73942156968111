<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import CountDown from '@/components/CountDown'

export default {
  name: 'loginLock',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/account/lock/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '昵称',
          type: 'input',
          key: 'realName'
        },
        {
          name: '手机号',
          type: 'input',
          key: 'phone'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'userId',
          title: 'ID',
          type: 'lt-90',
          isId: true,
          sorter: (a, b) => a.userId - b.userId
        },
        {
          dataIndex: 'username',
          title: '登录账号',
          filter: true,
          type: 'lt-100'
        },
        {
          dataIndex: 'realName',
          title: '用户昵称',
          filter: true,
          type: 'lt-100'
        },
        {
          dataIndex: 'phone',
          title: '用户手机号',
          filter: true,
          type: 'lt-100'
        },
        {
          dataIndex: 'lockDateTime',
          title: '锁定时间',
          filter: true,
          type: 'lt-100'
        },
        {
          dataIndex: 'surplusTime',
          title: '剩余时常',
          filter: true,
          type: 'lt-100',
          customRender: (text, records) => {
            return text && <CountDown deadline={text ?? 0} />
          }
        }
        // {
        //   dataIndex: 'action',
        //   title: '操作',
        //   type: 'buttonGroup',
        //   width: '10%',
        //   typeData: ({ records }) => {
        //     return [
        //       {
        //         name: '详情',
        //         onClick: () => this.$router.push(`/monitoring/logLogDetail?id=${records.id}`)
        //       },
        //       {
        //         name: '删除',
        //         type: 'pop',
        //         popTitle: '确认是否删除吗?',
        //         onClick: () =>
        //           api.command.del.call(this, {
        //             url: `/log/${records.id}`
        //           })
        //       }
        //     ]
        //   }
        // }
      ]
    },
    getButton() {
      return [
        {
          name: '解除锁定',
          isBatch: true,
          onClick: data => {
            let ids = data.map(e => e.userId)
            return api.command.edit.call(this, {
              url: '/account/lock/clearUserLock',
              params: {
                ids
              }
            })
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        showRowSelect={true}
      />
    )
  }
}
</script>
